.userBio{
    flex: 3;
    margin: 20px;
    padding-bottom:30px ;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    text-align: justify;
}
.icons{
    height: 25px;
}
.sidebarItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sidebarTitle{
    margin: 10px;
    padding:5px;
    width: 80%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
    font-family: sans-serif;
    color: inherit;
    font-weight: 600px;
    line-height: 20px;
    text-align:center;
}

.sidebarItem > img{
    margin-top: 15px; 
    border-radius: 15px;
}
.sidebarItem > p{
    padding: 30px;
}

.sidebarList{
    list-style: none;
    margin-bottom: 30px;
}
.sidebarListItem{
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}

.sidebarSocial{
    
   
    margin-right: 10px;
    cursor: pointer;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebarIcon{
font-size: 16px;
margin-left: 10px;
cursor: pointer;
}


.sidebarImg{
    width: 300px;
    height: 300px;
   margin: 5px;
}


@media (max-width: 768px){.userBio {
    /* Reduce the margin and padding of the sidebar element */
    margin: 10px;
    padding: 10px;
    /* Make the sidebar element fit the width of the screen */
    width: 100%;
  }}