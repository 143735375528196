.single{
    display: flex;
    
    
}

@media (max-width: 768px) {
    .single {
      /* Make the single element stack vertically */
      flex-direction: column;
    }}