/* Reset some default styles for the header */


/* Style the header container */
.header {
  position: relative;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

/* Style the header image */
.headerImage {
  width: 100%;
  height: 50vh; /* Use viewport height to make the image cover the entire header */
  object-fit: cover;
}

/* Create a grid layout for the header content */
.headerContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(6, 6, 6, 0.068); /* Add a semi-transparent background color to the content */
  /*color: #fff;  Text color on top of the image */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle box shadow */
  border-radius: 5px;
}

/* Style the header title */
.headerTitle {
  font-size: 36px;
  margin: 0;
}

/* Style the header link */
.headerLink {
  color: #fff;
  background-color: #6c6c53bb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.headerLink:hover {
  color: #6c6c53;
}

/* Style the header subtitle */
.headerSubtitle {
  font-size: 18px;
  margin: 0;
}
