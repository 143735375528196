.newsletter-container {
   width: 315px;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(172, 162, 162, 0.1);
    text-transform: none;
    color: inherit;
  }
  .newsletter-container:hover{
    width: 325px;
    transition: 0.5s;
     transform: 0.3s ease;
    transform: translateY(-10px);
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(142, 137, 137, 0.2);
   
  }
  .newsletter-container:not(:hover) {
    transition:0.5s ease;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: inherit;
  }
  
  .newsletter-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .buttonNewsletter{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .buttonNewsletter:hover {
    background-color: #0056b3;
  }
  