*input{
  text-transform: none;
}
.register{
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)), url(../../Pages/Login/background.webp);
background-size: cover;
}
.registerTitle{
    font-size: 50px;
}
.registerForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.registerForm > label{
    margin:10px 0;
}
.registerInput{
    padding: 10px;
    background-color:white;
    border: none;
}
.registerButton{
    margin-top: 20px;
    cursor:pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius:10px;
    padding: 10px;
}

.registerLoginButton{
    position:absolute;
    top:60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 10px;

}

@media (max-width: 768px) {
    .registerTitle {
      /* Reduce the font size of the register title */
      font-size: 40px;
    }
    .registerForm > label {
      /* Reduce the margin of the register form label */
      margin: 5px 0;
    }
    .registerInput {
      /* Reduce the padding of the register input */
      padding: 5px;
    }
    .registerButton {
      /* Reduce the margin and padding of the register button */
      margin-top: 10px;
      padding: 5px;
    }
    .registerLoginButton {
      /* Reduce the position, padding and font size of the register login button */
      top: 50px;
      right: 10px;
      padding: 5px;
      font-size: 14px;
    }
  }
  