/* Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 30px 0;
  text-transform: lowercase;
    bottom: 0px;
}

a {
  text-decoration: none;
  color: rgb(108, 20, 189);
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  max-width: 300px;
  margin-bottom: 20px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.5;
}

.social-icons a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #555;
  align-items: center;
}

@media (max-width: 768px) {
  .footer {
    width: 100%;
  }
  .footer-section {
    flex: 1;
    max-width: none;
    text-align: center;
  }
  .footer-section p {
    text-align: left;
  }
}
.social-icons i {
  justify-content: center;
}
