/* Sidebar.css */

.sidebar {
  padding-top: 50px;
    position: sticky;
    top: 20px;
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(129, 125, 125, 0.1);
    margin: 20px;
    border-radius: 5px;
    overflow-y: auto;
    flex: 3;
    height: 550px;
    color: inherit;
   
  }
  ul li:active{
    color: green;
  }

  
  .category {
    margin: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 10px;
    background: inherit;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(225, 220, 220, 0.1);
  }
  
  .sidebar:hover {
    background: inherit;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(215, 211, 211, 0.2);
  }
  
  .category:hover{
   
    transition: 0.5s;
     transform: 0.3s ease;
    transform: translateY(-5px);
    border-radius: 10px;
   
  }
  .category:not(:hover) {
    transition:0.5s ease;
  }
  
  /* .category:hover {
    background-color: #efefef;
    color:#b5cc31;
  } */
  
  h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    
    cursor: pointer;
    transition: color 0.2s;
    text-decoration: none;
    
  }
  

  