/* About Page Styles */
.about {
    display: flex;
    flex: 9;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    background-color: inherit;
   
  }
  p{
    text-align: left;
  }
  
  .about-header {
    margin-bottom: 20px;
  }
  
  .about-header h1 {
    font-size: 36px;
    font-weight: bold;
    color: inherit;
  }
  
  .about-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  
  .about-section {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: inherit;
    border: 1px solid inherit;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(247, 245, 245, 0.1);
    height: auto;
  }
  .about-section1 {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: inherit;
    border: 1px solid inherit;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(247, 245, 245, 0.1);
    height: auto;
  }
  .about-section2 {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: inherit;
    border: 1px solid inherit;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(247, 245, 245, 0.1);
    height: auto;
  }
  .about-section3 {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: inherit;
    border: 1px solid inherit;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(247, 245, 245, 0.1);
    height: auto;
  }
  .about-section4 {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: inherit;
    border: 1px solid inherit;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(247, 245, 245, 0.1);
    height: auto;
  }
  
  .about-section h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-section p {
    font-size: 16px;
    color: inherit;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .partner-logo {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    transition: filter 0.3s ease-in-out;
  }
  
  .partner-logo.active {
    filter: brightness(1.2); /* Lighten the image when active */
  }
  
  .partner-logo img {
    width: 100px;
    height: auto;
    filter: grayscale(100%);
  }
  
  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  
    ul {
      font-size: 14px;
    }
  
    .partner-logo img {
      width: 80px;
    }
  }
  
  
