.singlepost{
    flex:9;  
}
a{
    color:rgb(15, 23, 184);
}
.singlePostWrapper{
    flex:9;
    margin-bottom: 10%;
   margin: 0px;
    display: flex;
    flex-direction: column;
    padding: 0px;
}
.profilepic{
    width: 25px;
    height: 25px;
    border-radius: 25px;
}
.singlePostImg {
    width: 100%;
    height: 450px;
    border-radius: 5px;
    object-fit: contain;
}
.singlePostTitle{
    text-align: center;
    margin: 10px;
    font-family: 'Quicksand', sans-serif;
    font-size:30px ;
}
.singlePostTitleInput{
    text-align: center;
    margin: 10px;
    font-family: 'popin', Arial, Helvetica, sans-serif;
    font-size:30px ;
    border: none;
    border-bottom: 1px solid  gray ;
}
.singlePostTitleInput:focus{
    outline: none;
}
.singlePostEdit{
    float:right ;
    font-size: 16px;   
}
.singlePostIcon{
    margin-left: 10px;
    cursor: pointer;
}
.singlePostIcon:first-child{
    color:teal;
}
.singlePostIcon:last-child{
    color:tomato;
}
.singlePostInfo{
    margin-bottom: 10px;
    display:flex;
    justify-content:space-between;
    font-size: 16px;
    font-family: 'popin', 'arial', sans-serif;
    color: inherit;
    font-weight:bold;
    margin: 15px;

}
.singlePostAuthor{
    color:inherit;
}
.singlePostDesc{
    color: inherit;
    font-size: 18px;
    line-height: 25px;
    margin: 20px;
    text-align: justify;
    font-family: 'Quicksand', sans-serif;
    

}
.singlePostDescInput{
    border: none;
    /* color: #666; */
    color: inherit;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;

}
.singlePostDescInput:focus{
    outline: none;
}
.singlePostDesc::first-letter{
    margin-left: 15px;
    font-size: 30px;
    font-weight: 600;
}
.singlePostButton{
    border: none;
    background-color: teal;
    padding: 5px;
    width: 100px;
    color:inherit;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top:20px;
}
.share-buttons{
    width: 50px;
    padding: 10px;
    border: none;
    display: flex;
    justify-content: space-between; 
    
}
 button{
    cursor: pointer;
    background-color: inherit;
    border: none;
}
.share{
    height: 25px;}

@media (max-width: 768px){
    .singlepost {
        width: 100%;
      }
      .singlePostWrapper{
        padding: 0;
        margin: 0;
        
      }
      .singlePostImg{
        width: 100%;
        margin: 0;
        padding: 0;
       
        width: 100%;
        /* margin-left: 20px; */
        
            border-radius: 5px;
            object-fit: fill;
   
      }
    .singlePostTitle {
    /* Reduce the font size of the single post title */
    font-size: 24px;
  }
  .singlePostTitleInput {
    /* Reduce the font size of the single post title input */
    font-size: 24px;
  }
  .singlePostDesc {
    /* Reduce the font size of the single post description */
    font-size: 16px;
    margin: 0px;
  }
  .singlePostDescInput {
    /* Reduce the font size of the single post description input */
    font-size: 16px;
  }
  .singlePostInfo{
    flex-direction: column;
    
  }}
  @media (max-width: 640px){
    .singlePostImg{
        
        margin: 0;
        padding: 0;
       
    
        
            border-radius: 5px;
            object-fit: fill;
   
      }
  }
