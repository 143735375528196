.post {
    width: 90%;
    margin: 0px 25px 40px 25px;
  }
  
  .container {
    display: flex;
    flex-direction: row; /* Display horizontally */
    align-items: flex-start; /* Align items at the top */
    cursor: pointer;
    background: #fefefe;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .container:hover{
    background: inherit;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(143, 135, 135, 0.2);
  }
  .post:hover{
    width: 91%;
    transition: 0.5s;
     transform: 0.3s ease;
    transform: translateY(-10px);
   
  }
  .post:not(:hover) {
    transition:0.5s ease;
  }
  .postImg {
    width: 250px;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .postInfo {
    margin: 15px;
    display: flex; /* Display its children horizontally */
    flex-direction: column;
    flex-grow: 1; /* Allow it to grow and take remaining space */
    width: 100px;
  }
  .postDesc {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    color: inherit;
    line-height: 22px;
    margin-top: 5px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: justify;
  }
  
  .postTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: left;
  }
  
  .postDate {
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-size: 13px;
    color: inherit;
    margin-top: 15px;}
    .postCat {
        font-family: 'Poppins', sans-serif;
        font-size: 11px;
        color: inherit;
        line-height: 20px;
        margin-top: 15px;
        margin-right: 10px;
        cursor: pointer;
      }
  
  @media (min-width: 768px) {
    /* Media query for larger screens */
    .container {
      flex-direction: row; /* Horizontal layout for larger screens */
    }
  }
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Display vertically on small screens */
    }
    .post{
      justify-content: center;
      margin-left: 17.1px;
    }
  
    .postImg {
      width: 100%; /* Adjust the image width to fill the container */
      height: auto;
    }
  
    .postInfo {
      margin: 15px;
      width: auto; /* Reset the width for vertical layout */
    }
     
  }

  @media (max-width: 640px){
  .postImg {
    justify-content: center;
    height: 200px;
    width: 300px;
    margin-left: 1.4px;
  }}


  @media only screen and (max-width: 320px) {
   
    .postImg {
      justify-content: center;
      height: 200px;
      width: 270px;
      margin-left: 1.4px;
    }
  }