.postadmin {
  width: 90%;
  justify-content: center;
  height: auto;
  flex-wrap: wrap;
  display: flex;
  margin: 20px;
 margin-left: 50px;
  
}

.containeradmin {
  display: flex;
  flex-direction: row; /* Display horizontally */
  align-items: flex-start; /* Align items at the top */
  cursor: pointer;
 
}
/* .containeradmin:hover{
  background: #fefefe;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
.postadmin:hover{
  width: 91%;
  transition: 0.5s;
   transform: 0.3s ease;
  transform: translateY(-10px);
 
}
.postadmin:not(:hover) {
  transition:0.5s ease;
} */
.postImgadmin {
  width: 400px;
  height: 250px;
  object-fit: fill;
  border-radius: 15px;
 
}

.postInfoadmin {
  margin: 15px;
  display: flex; /* Display its children horizontally */
  flex-direction: column;
  flex-grow: 1; /* Allow it to grow and take remaining space */
  width: 100px;
}
.postDescadmin {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  color: inherit;
  line-height: 22px;
  margin-top: 5px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: justify;
}

.postTitleadmin {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: justify;
}

.postDateadmin {

  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-size: 13px;
  color: inherit;
  margin-top: 15px;}
  .postCatadmin {
      font-family: 'Poppins', sans-serif;
      font-size: 11px;
      color: #be9656;
      line-height: 20px;
      margin-top: 15px;
      margin-right: 10px;
      cursor: pointer;
    }

@media (min-width: 768px) {
  /* Media query for larger screens */
  .containeradmin {
    flex-direction: row; /* Horizontal layout for larger screens */
  }
}
@media (max-width: 768px) {
  .containeradmin {
    flex-direction: column; /* Display vertically on small screens */
  }
  .postadmin{
    justify-content: center;
    margin-left: 17.1px;
  }

  .postImgadmin {
    width: 100%; /* Adjust the image width to fill the container */
    height: auto;
  }

  .postInfoadmin {
    margin: 15px;
    width: auto; /* Reset the width for vertical layout */
  }
   
}

@media (max-width: 640px){
.postImgadmin {
  justify-content: center;
  height: 200px;
  width: 300px;
  margin-left: 1.4px;
}}