/* Updated .settings CSS */

.settings {
  
  display: flex;
   flex-wrap: wrap; /*Allow content to wrap to the next line on small screens */
}

.settingsWrapper {

  flex: 5; /* Adjusted flex value */
  padding: 20px;
  max-width: 800px; /* Added max-width to prevent excessive width on larger screens */
  margin: 0 auto; /* Center align on larger screens */
}

.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsUpdateTitle {
  font-size: 24px; /* Reduced font size for better readability */
  margin-bottom: 20px;
  color: lightcoral;
}

.settingsDeleteTitle {
  color: red;
  font-size: 12px;
  cursor: pointer;
}

.settingsForm {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-top: 20px;
}

.settingsPP {
  display: flex;
  align-items: center; /* Adjusted alignment */
  margin: 10px 0;
}
label{
  font-size: 15px;
}

textarea {
  font-size: 15px; /* Reduced font size for better readability */
  padding: 15px; /* Reduced padding for better spacing */
  width: 100%;
  height: 400px;
  border: none;
  /* background-color: #f2f2f2; Background color */
  /* Added margin for spacing */
}



.settingsPP > img {
  width: 70px;
  height: 70px;
  border-radius: 50%; /* Made the profile picture circular */
  object-fit: cover;
}

.settingsPPIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: lightcoral;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add a transform animation on hover */
}

.settingsPPIcon:hover {
  transform: scale(1.1); /* Scale up the icon on hover */
}

.settingsForm > input {
  font-size: 18px; /* Reduced font size for better readability */
  margin: 10px 0;
  height: 30px;
  border: none;
  border-bottom: 1px solid lightgray;
}

.settingsForm > input:focus {
  outline: none;
}

.settingsSubmit {
  width: 150px;
  align-self: center;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: teal;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition animations */
}

.settingsSubmit:hover {
  background-color: rgb(65, 162, 170); /* Darker teal on hover */
  transform: scale(1.05); /* Slightly scale up the button on hover */
}

@media (max-width: 768px) {
  .settingsWrapper {
    padding: 10px; /* Reduced padding for smaller screens */
  }
  .settingsUpdateTitle {
    font-size: 20px; /* Further reduced font size for small screens */
  }
  .settingsDeleteTitle {
    font-size: 10px;
  }
  .settingsPP > img {
    width: 50px;
    height: 50px;
  }
  .settingsPPIcon {
    width: 20px;
    height: 20px;
  }
  .settingsForm > input {
    font-size: 16px;
    height: 25px;
  }
}
