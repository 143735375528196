
.menu {
  margin-top: 20px;
  flex: 3;
  gap: 10px;
  border-color: inherit;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(219, 210, 210, 0.1);
  margin-right: 20px;
  margin-left: 20px;
  justify-content: center;
  
}
.cardMenu{
  /* background: #fefefe; */
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(203, 196, 196, 0.1);
    
}
.img {
  width: 100%;
  height: 200px;
  object-fit: fill;
  border-radius: 10px;
}
p{
  padding: 0px;
  margin: 0px;
  margin-left:10px;
  font-size: 15px;
  color: inherit;

}
h3 {
  /* color: #555; */
  text-align: center;
}
label{
  margin: 10px;
}

.titleMenu {
  padding: 10px;
  height: 63px;
  font-family: sans-serif;
  /* color: #222; */
  text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  font-weight: 600;
  line-height: 25px;
  ;
  margin-left: auto; /* Align to the right */
  margin-right: auto; /* Align to the right */
}

.post{
  /* background: #fefefe; */
    margin-left: 15px;
   
    
}



.postMenu {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  color: inherit;
  line-height: 22px;
  margin-top: 3px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: justify;
  margin: 10px;
  margin-top: 10px;
  
}

.buttonMenu {
  color: green;

  background-color: white;
  font-size: 15px;
  font-weight: 500; /* Corrected font-weight */
  cursor: pointer;
  width: 100px;
  margin: 10px;
  border-radius: 35px;
  padding: 5px;
  text-align: center; /* Center-align text */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

.buttonMenu:hover {
  color: white;
  background-color: rgb(83, 141, 83);
}

.buttonMenu:active {
  background-color: rgb(88, 175, 88);
}


@media (max-width: 768px) {
  .menu {
    margin: 10px;
    padding: 10px;
    width: 100%;
  }
}
