/* UserList.css */

.user-list-containeruser {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
   
    
  }
  
  .user-listuser {
    list-style: none;
    padding: 0;
   
  }
  
  .user-itemuser {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-color: inherit;
    padding: 10px;
    background-color: inherit;
    box-shadow: 0px 4px 6px rgba(142, 136, 136, 0.1);
    border-radius: 5px;
   
  }
  
  .user-avataruser img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    display: block;
  }
  
  .user-detailsuser {
    flex-grow: 1;
    
  }
  
  .user-usernameuser {
    font-size: 18px;
    font-weight: bold;
    display: flex;
  }
  