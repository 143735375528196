/* SingleUser.css */
.button {
  color: green;
  border: 1px solid green;
  background-color: white;
  font-size: 15px;
  font-weight: 500; /* Corrected font-weight */
  cursor: pointer;
  width: auto;
  margin: 10px;
  border-radius: 35px;
  padding: 5px;
  text-align: center; /* Center-align text */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

.button:hover {
  color: white;
  background-color: rgb(83, 141, 83);
}

.button:active {
  background-color: rgb(88, 175, 88);
}
/* Add a media query for responsiveness */
@media (max-width: 768px) {
  .user-item {
    flex-direction: column;
    align-items: center;
  }

  .user-avatar img {
    width: 100px; /* Adjust the width as needed */
    height: auto; /* Allow the height to adjust proportionally */
    margin-right: 0; /* Remove the margin */
    margin-bottom: 10px; /* Add space between the picture and content */
  }
}

/* Keep your existing styles for larger screens */
.user-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.user-list {
  list-style: none;
  padding: 0;
}
.icons{
  height: 25px;
}

.user-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-color: inherit;
  padding: 10px;
  background-color:inherit;
  box-shadow: 0px 4px 6px rgba(172, 166, 166, 0.1)
}

.user-avatar img {
  width: 300px; /* Adjust the width for the big rectangle picture */
  height: auto; /* Allow the height to adjust proportionally */
  border-radius: 10px; /* Apply a border radius for the rectangle shape */
  margin-right: 10px;
  top:0;
}

.user-details {
  flex-grow: 1;
}

.user-username {
  font-size: 18px;
  font-weight: bold;
}

.sidebarSocial{
  margin-left: 8px;
  justify-content: start;
}
