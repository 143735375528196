/* Updated .write CSS */

.write {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 100px;
  }
  
  .writeImg {
    width: 100%;
    max-width: 800px; /* Adjust the max-width as needed */
    border-radius: 10px;
    height: auto; /* Make the image responsive */
    object-fit: cover;
    margin-bottom: 20px; /* Added margin for spacing */
  }
  
  .writeForm {
    position: relative;
    width: 100%;
    max-width: 800px; /* Adjust the max-width as needed */
  }
  
  .writeFormGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Added margin for spacing */
  }
  .writeFormGroupQuill{
    width: 100%;
    background-color: #f2f2f2;
    margin-bottom: 20px;
    
  }
 
option{
  width: 100px;
  font-size: 15px;
}
select{
  width: 150px;
  font-size: 15px;
}
  .writeIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%; /* Circular icon */
    cursor: pointer;
    border: 1px solid rgba(121, 118, 118, 0.7); /* Adjusted border color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; /* Slightly smaller font size */
    color: rgba(121, 118, 118, 0.7); /* Adjusted icon color */
    margin-bottom: 10px; /* Added margin for spacing */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  }
  
  .writeIcon:hover {
    background-color: teal;
    color: white;
  }
  
  .writeInput {
    font-size: 20px; /* Reduced font size for better readability */
    border: none;
    padding: 15px; /* Reduced padding for better spacing */
    width: 100%;
    border-radius: 5px; /* Slightly rounded input */
    background-color: #f2f2f2; /* Background color */
    margin-bottom: 20px; /* Added margin for spacing */
  }
  
  .writeInput:focus {
    outline: none;
    border: 1px solid teal; /* Highlight border on focus */
  }
  
  .writeText {
    font-size: 18px; /* Reduced font size for better readability */
    height: 150px; /* Increased height for better user experience */
    padding: 15px; /* Reduced padding for better spacing */
    border: 1px solid #ccc; /* Added border for the textarea */
    border-radius: 5px; /* Slightly rounded textarea */
    resize: none; /* Prevent resizing */
    margin-bottom: 20px; /* Added margin for spacing */
  }
  
  .writeSubmit {
    position: absolute;
    top: 20px;
    right: 20px; /* Adjusted right position */
    color: white;
    background-color: teal;
    padding: 10px 20px; /* Adjusted padding for better button size */
    border: none;
    border-radius: 5px; /* Slightly rounded button */
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  }
  
  .writeSubmit:hover {
    background-color: rgb(65, 162, 170); /* Darker teal on hover */
  }
  