input{
  text-transform: none;
}
.login{
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)), url(../Login/background.webp);
background-size: cover;
  color: black;
}
.LoginTitle{
    font-size: 50px;
    color: inherit;
}
.loginForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm > label{
    margin:10px 0;
}
.loginInput{
    padding: 10px;
    background-color:white;
    border: none;
    color: inherit;
}
.loginButton{
    margin-top: 20px;
    cursor:pointer;
    background-color: lightcoral;
    border: none;
    color: inherit;
    border-radius:10px;
    padding: 10px;
}
.loginButton:disabled{
    /* cursor:not-allowed; */
    background-color: rgb(236, 34, 34);   
}

.loginRegisterButton{
    position:absolute;
    top:60px;
    right: 20px;
    background-color: teal;
    cursor: pointer;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .loginTitle {
      /* Reduce the font size of the login title */
      font-size: 40px;
    }
    .loginForm > label {
      /* Reduce the margin of the login form label */
      margin: 5px 0;
    }
    .loginInput {
      /* Reduce the padding of the login input */
      padding: 5px;
    }
    .loginButton {
      /* Reduce the margin and padding of the login button */
      margin-top: 10px;
      padding: 5px;
    }
    .loginRegisterButton {
      /* Reduce the position, padding and font size of the login register button */
      top: 50px;
      right: 10px;
      padding: 5px;
      font-size: 14px;
    }
  }
  