*{margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    text-decoration: none;
    list-style: none;
   
    }
    
    .scroll{
        margin-top: 4px;
        margin-left: 3px;
        height: 15px;
        width: 17px;
    }
    
    .top{
        height: 50px;
        width: 100%;
        position: -webkit-sticky;
        background-color: inherit;
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        font-family: 'Josefin Sans', sans-serif;
        z-index: 1000;
        justify-content: space-between;
        box-shadow: 0 5px 10px rgb(83, 81, 81);
        height: auto;
        background-color: white;
    
    }
    
    header{
        position: sticky;
        top: 0; left: 0; right:0;
        background: white;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        padding: 0px 7%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 999;
    }
    
    header .logo{
        font-weight: bolder;
        font-size: 25px;
        /* color:#333; */
    
    }
    
    header .navbar{
        list-style: none;
    
    }
    header .navbar ul li{
        position: relative;
        float: left;
    }
    
    header .navbar ul li a{
        font-size: 20px;
        padding: 20px;
        color: #333;
        display: flex;
    }
    
    header .navbar ul li a:hover{
        border-bottom: 2px solid gray;
        
        
    }
    
    header .navbar ul li ul{
        position: absolute;
        left: 0;
        width: 400px;
       
        margin: 0;
        padding: 0;
        background: #fff;
        display: none;
    
    }
    header .navbar ul li ul li{
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, .1);
    }
    
    header .navbar ul li ul li ul{
       left: 200px;
       top:0px;
    }
    
    header .navbar ul li:focus-within>ul,
    /* header .navbar ul li:hover>ul{
        display:initial ;
    
    } */
        
    
    header .navbar ul li:active>ul{
        display:initial ;
    
    }
    .submenu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 1;
      }
      
      /* Styles for submenu links container */
      .articles-container {
        max-height: 450px; /* Adjust the maximum height as needed */
        overflow-y: auto; /* Enable vertical scrolling */
        background-color: #fefefe;
        border-radius: 10px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      }
    #menu-bar{
        display: none;
    }
    header label{
        font-size: 20px;
        /* color:#333; */
        cursor: pointer;
        display: none;
    
    }
    .topImg{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
        position: absolute;
        
        top:10px;
        right: 30px;
    }
    
    
    
    @media(max-width:800px){
    header{
        padding: 20px;
    }
    header label{
        display: initial;
    }
    header .navbar{
        position: absolute;
        top:100%; left:0; right:0;
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, .1);
        display: none;
    }
    header .navbar ul li{
        width: 100%;
    }
    header .navbar ul li ul{
        position: relative;
        width: 100%;
    }
    header .navbar ul li ul li {
        background: #eee;
    
    }
    header .navbar ul li ul li ul{
        width: 100%;
        left:0;
    
    }
    #menu-bar:checked ~ .navbar{
        display:initial;
        opacity: 95%;
    }
    }
    @media (max-width: 768px) {
        header {
          padding: 20px;
        }
      
        header label {
          display: initial;
        }
      
        header .navbar {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background: #fff;
          border-top: 1px solid rgba(0, 0, 0, .1);
          display: none;
        }
      
        header .navbar ul li {
          width: 100%;
        }
      
        header .navbar ul li ul {
          position: relative;
          width: 100%;
        }
      
        header .navbar ul li ul li {
          background: #eee;
        }
      
        #menu-bar:checked ~ .navbar {
          display: initial;
          opacity: 95%;
        }
      }