.posts{
    flex: 6;
    flex-direction: column;
    /* flex-wrap: wrap; */
    margin-left: 50px;
    margin-top: 20px;
    
    
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination button:hover {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
  }
  

@media (max-width: 768px){.posts {
    /* Reduce the margin of the posts element */
    margin: 10px;
  }}